const defaultOptions = {
  autoHideDelay: 10000,
  // noAutoHide: true,
  toaster: 'b-toaster-bottom-right'
}

export default {
  methods: {
    notify (title, text) {
      this.$root.$bvToast.toast(text, { ...defaultOptions, title })
    },
    notifyError (title, text) {
      this.$root.$bvToast.toast(text, { ...defaultOptions, title, variant: 'danger' })
    },
    notifySuccess (title, text) {
      this.$root.$bvToast.toast(text, { ...defaultOptions, title, variant: 'success' })
    },
    notifyWarning (title, text) {
      this.$root.$bvToast.toast(text, { ...defaultOptions, title, variant: 'warning' })
    }
  }
}
