<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else>
    <div class="settings-item__wrapper" v-for="item in settings" :key="item.code">
      <div class="settings-item">
        <div class="settings-item__title h1 mb-20">{{ item.title }}</div>

        <template v-if="item.options && item.options.length > 0">
          <b-form-group :v-slot="item.code">
            <b-form-radio
              v-for="option in item.options"
              :key="option.value"

              :aria-describedby="item.code"
              :name="item.code"
              v-model="item.value"
              :value="option.value"
              @change="saveOption(item.code, $event)"
            >
              {{ option.text }}
            </b-form-radio>
          </b-form-group>
        </template>
        <template v-else>
          <b-form-input v-model="item.value" @change="saveOption(item.code, $event)" />
        </template>
      </div>
      <hr>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NotifyMixin from '@/mixins/NotifyMixin.js'

export default {
  name: 'PlanSettings',
  mixins: [NotifyMixin],
  data () {
    return {
      isLoading: true,
      settingsList: [
        /* {
          title: 'Режим плана продаж',
          code: 'planning_mode',
          value: '',
          options: [
            { value: true, text: 'С предварительным планированием' },
            { value: false, text: 'Без предварительного планирования' }
          ]
        }, */
        {
          title: 'В чем отображать планы менеджеров в рейтинге',
          code: 'measure',
          value: '',
          options: [
            { value: 'R', text: 'Рубли' },
            { value: 'P', text: 'Проценты' }
          ]
        },
        {
          title: 'Включить назначение нижнего порога плана',
          code: 'min_sum_mode',
          value: '',
          options: [
            { value: true, text: 'Да' },
            { value: false, text: 'Нет' }
          ]
        },
        /* {
          title: 'Кому вы будете продавать',
          code: 'segment',
          value: '',
          options: [
            { value: 'B2B', text: 'B2B' },
            { value: 'B2C', text: 'B2C' }
          ]
        },
        {
          title: 'Сущность',
          code: 'entity',
          value: '',
          options: [
            { value: 'deal', text: 'Сделки' }
          ]
        },
        {
          title: 'Режим сделок',
          code: 'deal_mode',
          value: '',
          options: [
            { value: 'closed', text: 'Успешные закрытые сделки' },
            { value: 'status', text: 'С определенной стадии сделок' }
          ]
        }, */
        {
          title: 'Название стадии, когда счет отправлен',
          code: 'predict_deal_stage_name',
          value: ''
          /* required: {
            code: 'deal_mode',
            value: 'status'
          } */
        },
        {
          title: 'Название стадии, когда счет оплачен',
          code: 'paid_deal_stage_name',
          value: ''
          /* required: {
            code: 'deal_mode',
            value: 'status'
          } */
        },
        {
          title: 'Копировать сотрудников плана с предыдущего месяца, если не заданы в текущем',
          code: 'copy_plan',
          value: '',
          options: [
            { value: true, text: 'Да' },
            { value: false, text: 'Нет' }
          ]
        },
        {
          title: 'Как рассчитывать сумму сделок',
          code: 'deal_sum',
          value: '',
          options: [
            { value: 'mixed', text: 'По товарам, если они есть в сделке. Если нет, то по полю "сумма сделки"' },
            { value: 'product', text: 'По товарам' },
            { value: 'field', text: 'По полю "сумма сделки"' }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapState('sale/settings', ['entity', 'deal_mode', 'paid_deal_stage_name', 'predict_deal_stage_name', 'paid_deal_date', 'predict_deal_date', 'measure', 'segment', 'planning_mode', 'min_sum_mode', 'copy_plan', 'deal_sum']),
    settings () {
      const result = []

      this.settingsList.forEach(el => {
        if (el.required) {
          const optionIndex = this.settingsList.findIndex(val => val.code === el.required.code)
          if (this.settingsList[optionIndex].value === el.required.value) {
            result.push(el)
          }
        } else {
          result.push(el)
        }
      })

      return result
    }
  },
  async created () {
    await this.$store.dispatch('sale/settings/fetchSettings')
    await this.fillSettingsValues()

    this.isLoading = false
  },
  methods: {
    async fillSettingsValues () {
      this.settingsList.forEach(el => {
        el.value = this[el.code]
      })
    },
    async saveOption (name, value) {
      try {
        await this.$api.post('option/save', {
          [`sale_${name}`]: value
        })
        this.notifySuccess('Сохранение настроек', 'Настройки сохранены')
      } catch (e) {
        this.notifyError('Сохранение настроек', 'Ошибка сохранения')
      }
    }
  }
}
</script>

<style scoped>
.settings-item {
  margin-bottom: 32px;
}
.settings-item__wrapper:not(:first-child) .settings-item {
  margin-top: 32px;
}
</style>
