<template>
  <div>
    <TopPanel
      back
      backText="Вернуться назад"
      @back-click="$router.go(-1)"
    />

    <Block>
      <SaleSettings />
    </Block>
  </div>
</template>

<script>
import SaleSettings from '@/components/sale/Settings'
import TopPanel from '@/components/TopPanel'

export default {
  components: {
    SaleSettings,
    TopPanel
  }
}

</script>
